import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Heading } from "./typography"
import Gut from "./gut"
import BookThumbSearch from "./bookThumbSearch"
import algoliasearch from "algoliasearch/lite"
import { Col, Row } from "react-grid-system"
const client = algoliasearch("SHKFB9MFHO", "5291c471c1cd53fb970b345cf32c6ab2")
const index = client.initIndex("books")
const requestOptions = {
  headers: { "X-Algolia-UserToken": "SHKFB9MFHO" },
}

const SearchResults = ({ query }) => {
  const [results, setResults] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    setIsLoading(true)
    index.search(query, requestOptions).then(({ hits }) => {
      setResults(hits)
      setIsLoading(false)
    })
  }, [query])
  return (
    <Wrapper>
      {isLoading ? (
        <p>Caricamento...</p>
      ) : (
        <Gut gutter={2}>
          <Heading>Risultati della ricerca</Heading>
          <Row>
            {results.map(book => (
              <Col xs={6} sm={4} lg={3} key={book.objectID}>
                <BookThumbSearch book={book} />
              </Col>
            ))}
          </Row>
        </Gut>
      )}
    </Wrapper>
  )
}
const Wrapper = styled.div`
  min-height: 40vh;
`

export default SearchResults
