import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Body, Caption, HeadingSmall } from "./typography"
import { Text } from "theme-ui"

const BookThumbSearch = ({ book }) => (
  <Wrapper>
    <Link to={`/catalogo/${book.slug}`}>
      <Content>
        <ImageWrapper>
          <img src={book.cover.url} alt={book.title} />
        </ImageWrapper>
        <Details>
          <Caption>
            {book.authors.map((author, i) => (
              <span key={author.id}>
                {author.name}
                {i < book.authors.length - 1 ? ", " : null}
              </span>
            ))}
          </Caption>
          <HeadingSmall>{book.title}</HeadingSmall>
          {book.price === 0 ? (
            book.newEdition ? (
              <strong>Fuori catalogo - Sostituito da nuova edizione</strong>
            ) : book.digitalDownload !== "" ? (
              <strong>Fuori catalogo - Disponibile solo in e-book</strong>
            ) : (
              <strong>Fuori catalogo</strong>
            )
          ) : (
            <strong>
              € {book.price.toFixed(2).toString().replace(/\./g, ",")}
            </strong>
          )}
        </Details>
      </Content>
    </Link>
  </Wrapper>
)
const ImageWrapper = styled.div`
  padding: 2rem 1rem;
  background: #f6f6f6;
`
const Wrapper = styled.div`
  height: 100%;
  margin-bottom: 3rem;
  a {
    text-decoration: none;
    display: block;
    &:hover {
      opacity: 0.8;
      h4 {
        text-decoration: underline;
      }
    }
  }
`
const Content = styled.div`
  & > * {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`
const Details = styled.div`
  & > * {
    margin-bottom: 0.5rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default BookThumbSearch
