import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SearchForm from "../components/searchForm"
import SearchResults from "../components/searchResults"
import { Container, Row, Col } from "react-grid-system"
import { Box } from "theme-ui"

const SearchPage = ({ location }) => {
  const [query, setQuery] = useState(location.state ? location.state.query : "")
  return (
    <Layout>
      <SEO title="Home" />
      <Container>
        <Row>
          <Col>
            <Box sx={{ mt: [3, 4, 4], mb: [3, 4, 4] }}>
              <SearchForm query={query} setQuery={setQuery} />
            </Box>
            <SearchResults query={query} />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default SearchPage
